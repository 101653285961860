
export default {
  props: {
    uuid: {
      require: true,
      type: String
    },
    prefix: {
      type: String,
      default: ""
    }
  },

  render(h) {
    const scriptCode = "'use strict'; !(function() {   window.FeedbackCompanyWidgets = window.FeedbackCompanyWidgets || {queue: [],   loaders: []};var options = {  uuid: '"+this.uuid+"', version: '1.2.1',  prefix: '"+this.prefix+"' };if (void 0 === window.FeedbackCompanyWidget) {if ((window.FeedbackCompanyWidgets.queue.push(options),!document.getElementById('__fbcw_FeedbackCompanyWidget'))) { var scriptTag = document.createElement('script'); (scriptTag.onload = function() {if (window.FeedbackCompanyWidget) for (; 0 < window.FeedbackCompanyWidgets.queue.length ;) (options = window.FeedbackCompanyWidgets.queue.pop()), window.FeedbackCompanyWidgets.loaders.push( new window.FeedbackCompanyWidgetLoader(options) ); }), (scriptTag.id = '__fbcw_FeedbackCompanyWidget'), (scriptTag.src =  'https://www.feedbackcompany.com/includes/widgets/feedback-company-widget.min.js'), document.body.appendChild(scriptTag); } } else window.FeedbackCompanyWidgets.loaders.push( new window.FeedbackCompanyWidgetLoader(options) ); })();"
    const vm = this;
    return h(
      "div",
      {
        attrs: {
          class: "feedback-company-widget"
        }
      },
      [
        h(
          "script",
          {
            attrs: {
              id: `__fbcw__${vm.prefix}${vm.uuid}`
            }
          },
          scriptCode
        )
      ]
    );
  }
};
